enum Language {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
}

const isoLocale = [
  'de-AT',
  'de-CH',
  'de-DE',
  'de-IT',
  'de-LI',
  'de-LU',
  'en-GB',
  'es-ES',
  'fr-BE',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'it-CH',
  'it-IT',
  'nl-BE',
  'nl-NL',
] as const;

type IsoLocale = typeof isoLocale[number];

type Locale = {
  code: string;
  fallback?: string;
  isCatchallLocale?: boolean;
  iso: IsoLocale;
  useInAlgolia?: boolean;
};

const DEFAULT_LOCALE: Locale = {
  code: 'uk',
  iso: 'en-GB',
};

const locales = [
  // German
  {
    code: Language.DE,
    isCatchallLocale: true,
    iso: 'de-DE',
    useInAlgolia: true,
  },
  {
    code: 'de-at',
    fallback: Language.DE,
    iso: 'de-AT',
  },
  {
    code: 'de-ch',
    fallback: Language.DE,
    iso: 'de-CH',
  },
  {
    code: 'de-it',
    fallback: Language.DE,
    iso: 'de-IT',
  },
  {
    code: 'de-lu',
    fallback: Language.DE,
    iso: 'de-LU',
  },
  {
    code: 'de-li',
    fallback: Language.DE,
    iso: 'de-LI',
  },

  // Dutch
  {
    code: Language.NL,
    isCatchallLocale: true,
    iso: 'nl-NL',
    useInAlgolia: true,
  },
  {
    code: 'nl-be',
    fallback: Language.NL,
    iso: 'nl-BE',
  },

  // French
  {
    code: Language.FR,
    isCatchallLocale: true,
    iso: 'fr-FR',
    useInAlgolia: true,
  },
  {
    code: 'fr-be',
    fallback: Language.FR,
    iso: 'fr-BE',
  },
  {
    code: 'fr-ch',
    fallback: Language.FR,
    iso: 'fr-CH',
  },
  {
    code: 'fr-lu',
    fallback: Language.FR,
    iso: 'fr-LU',
  },

  // English
  {
    code: Language.EN,
    isCatchallLocale: true,
    iso: 'en-GB',
  },
  {
    code: 'uk',
    isCatchallLocale: true,
    iso: 'en-GB',
    useInAlgolia: true,
  },

  // Italian
  {
    code: Language.IT,
    isCatchallLocale: true,
    iso: 'it-IT',
    useInAlgolia: true,
  },
  {
    code: 'it-ch',
    fallback: Language.IT,
    iso: 'it-CH',
  },

  // Spanish
  {
    code: Language.ES,
    isCatchallLocale: true,
    iso: 'es-ES',
    useInAlgolia: true,
  },
] as const satisfies Locale[];

type LocaleCode = typeof locales[number]['code'];

const LOCALE_COOKIE_KEY = 'juniqe-locale';

export {
  DEFAULT_LOCALE,
  isoLocale,
  type IsoLocale,
  Language,
  type Locale,
  LOCALE_COOKIE_KEY,
  type LocaleCode,
  locales,
};
